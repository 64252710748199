import alterpressApi from './base';

import { IClientTeam } from '../../models/client-team.model';
import { IClientTeamValues } from '../../formik/client-team.form';

export const clientTeamApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['CLIENT_TEAM', 'CLIENT_PROJECT', 'CLIENT_MEMBER'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getClientTeam: builder.query<IClientTeam, number | string | undefined | null>({
                query: (teamId) => `client-teams/${teamId}`,
                providesTags: ['CLIENT_TEAM', 'CLIENT_PROJECT', 'CLIENT_MEMBER'],
            }),
            getAllClientTeam: builder.query<IClientTeam[], void>({
                query: () => `client-teams/all`,
                providesTags: ['CLIENT_TEAM', 'CLIENT_PROJECT', 'CLIENT_MEMBER'],
            }),
            createClientTeam: builder.mutation<IClientTeam, IClientTeamValues>({
                query: (data) => ({
                    url: `client-teams`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_TEAM'],
            }),
            updateClientTeam: builder.mutation<
                IClientTeam,
                { teamId: number | string; data: IClientTeamValues }
            >({
                query: ({ data, teamId }) => ({
                    url: `client-teams/${teamId}`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_TEAM'],
            }),
            deleteClientTeam: builder.mutation<void, number | string>({
                query: (teamId) => ({
                    url: `client-teams/${teamId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['CLIENT_TEAM'],
            }),
        }),
    });

export const {
    useGetAllClientTeamQuery,
    useGetClientTeamQuery,
    useCreateClientTeamMutation,
    useUpdateClientTeamMutation,
    useDeleteClientTeamMutation,
} = clientTeamApi;
