import React from 'react';
import { Formik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';

import {
    sectionBox,
    container,
    tableGrid,
    createBox,
    loader,
    grid,
    loading,
} from './client-team-listing.module.scss';
import { ISection } from '../../models/section.model';
import { TLocale } from '../../locale';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { IClientTeam } from '../../models/client-team.model';
import useTranslations from '../../hooks/use-translations';
import { useList } from '../../hooks/use-list';
import { useClient } from '../../hooks/use-client';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import { useModal } from '../../hooks/use-modal';
import { useClientTeam } from '../../hooks/use-client-team';
import { useClientPermission } from '../../hooks/use-client-permission';
import { usePagePathname } from '../../hooks/use-page-pathname';

import Section from '../hoc/section';
import Loader from '../atoms/loader';
import Table, { ITableProps } from '../organisms/table';
import Button from '../atoms/button';
import Tooltip from '../atoms/tooltip';
import IconFactory from '../hoc/icon-factory';
import HandleFormikChange from '../hoc/handle-formik-change';
import AlertInfo from '../molecules/alert-info';
import DashboardHeader from '../molecules/dashboard-header';
import NoPermissionInfo from '../organisms/no-permission-info';

interface IClientTeamListingProps {
    section: ISection;
    TitleTag?: React.ElementType;
}

interface IClientTeamListingQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const ClientTeamListing: React.FC<IClientTeamListingProps> = ({ section, TitleTag = 'h2' }) => {
    const { style, css, sectionId } = section;
    const t = useTranslations('ClientTeamListing');
    const client = useClient();
    const { allPage } = useStaticQuery<IClientTeamListingQueryResult>(query);
    const teamPreviewPage = usePagePathname(allPage, 'client-team-preview');
    const teamCreatePage = usePagePathname(allPage, 'client-team-form');
    const { addModal } = useModal();
    const team = useClientTeam();
    const canTeams = useClientPermission(['can-teams']);

    const {
        status,
        isInitialLoading,
        items,
        paginationPaths,
        handleChange,
        values,
        pagination,
        sort,
        isEmpty,
        refetchList,
    } = useList<IClientTeam>({
        endpoint: `/client-teams`,
        token: client.data?.token.token,
        perPage: 6,
    });

    const handleDeleteTeam = (teamId: number) => {
        addModal({
            modalKey: 'delete-confirm-modal',
            modalProps: { onConfirm: handleDeleteTeamConfirmation(teamId) },
        });
    };

    const handleDeleteTeamConfirmation = (teamId: number) => {
        return async () => {
            try {
                await team.delete.fetch(teamId).unwrap();
                refetchList();
            } catch {
                addModal({ modalKey: 'delete-error-modal' });
            }
        };
    };

    if (!canTeams) {
        return <NoPermissionInfo isFullHeight={true} reason="role" />;
    }

    return (
        <Section
            className={sectionBox}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading && <Loader className={loader} />}
            {!isInitialLoading && (
                <Formik onSubmit={() => {}} initialValues={values || {}} enableReinitialize={true}>
                    {(formik) => (
                        <div className={`${container} ${team.delete.isLoading ? loading : ''}`}>
                            <HandleFormikChange onChange={handleChange} />
                            <DashboardHeader TitleTag={TitleTag} hint={t.hint}>
                                {t.title}
                            </DashboardHeader>
                            <div className={createBox}>
                                <Button as="link" to={teamCreatePage}>
                                    {t.new}
                                </Button>
                                <Tooltip>{t.newHint}</Tooltip>
                            </div>
                            {isEmpty && <AlertInfo>{t.empty}</AlertInfo>}
                            {!isEmpty && (
                                <Table
                                    tableClassName={tableGrid}
                                    headerCells={getHeaderCells(t)}
                                    rows={getTeamRows(
                                        t,
                                        items,
                                        teamPreviewPage,
                                        teamCreatePage,
                                        handleDeleteTeam
                                    )}
                                    status={status}
                                    totalCount={pagination?.totalCount || 0}
                                    paginationPaths={paginationPaths}
                                    formik={formik}
                                    sort={sort}
                                />
                            )}
                        </div>
                    )}
                </Formik>
            )}
        </Section>
    );
};

function getHeaderCells(t: TLocale['ClientTeamListing']): ITableProps['headerCells'] {
    return [
        { label: t.id },
        { label: t.name },
        { label: t.memberCount },
        { label: t.createdAt, field: 'createdAt' },
        { label: t.projects },
        { label: '' },
    ];
}

function getTeamRows(
    t: TLocale['ClientTeamListing'],
    teams: IClientTeam[],
    teamPreviewPage: string,
    teamCreatePage: string,
    onDeleteTeam: (teamId: number) => void
): ITableProps['rows'] {
    return teams.map((team) => {
        return [
            {
                type: 'data',
                label: t.id,
                value: team.teamId,
                valueStyle: 'bold',
            },
            {
                type: 'data',
                label: t.name,
                value: team.name,
            },
            {
                type: 'data',
                label: t.memberCount,
                value: team.members.length,
            },
            {
                type: 'data',
                label: t.createdAt,
                value: getDateFromUnixTimestamp(team.createdAt, 'dash'),
            },
            {
                type: 'data',
                label: t.projects,
                value: team.projectNames.length > 0 ? team.projectNames.join(', ') : '-',
            },
            {
                type: 'action',
                actions: [
                    {
                        stylePreset: 'danger',
                        size: 'medium',
                        shape: 'circle',
                        children: <IconFactory icon="trash" />,
                        onClick: () => onDeleteTeam(team.teamId),
                    },
                    {
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        shape: 'circle',
                        to: `${teamCreatePage}?id=${team.teamId}`,
                        children: <IconFactory icon="edit" />,
                    },
                    {
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        children: t.details,
                        to: `${teamPreviewPage}?id=${team.teamId}`,
                    },
                ],
            },
        ];
    });
}

export const query = graphql`
    query {
        allPage(filter: { type: { in: ["client-team-preview", "client-team-form"] } }) {
            edges {
                node {
                    pathname
                    type
                    locale
                }
            }
        }
    }
`;

export default ClientTeamListing;
