import { useMemo } from 'react';

import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import {
    useGetClientTeamQuery,
    useCreateClientTeamMutation,
    useDeleteClientTeamMutation,
    useUpdateClientTeamMutation,
    useGetAllClientTeamQuery,
} from '../redux/api/client-team';

export type TUseClientTeamQueries = 'single' | 'all';

interface IUseClientTeamConfig {
    queries?: TUseClientTeamQueries[];
    teamId?: number | string | null;
}

export const useClientTeam = ({ queries = [], teamId }: IUseClientTeamConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');

    const skipTeamQuery = !queries.includes('single') || !teamId;
    const skipAllQuery = !queries.includes('all');

    const getTeamQuery = useGetClientTeamQuery(teamId, { skip: skipTeamQuery });
    const getAllQuery = useGetAllClientTeamQuery(undefined, { skip: skipAllQuery });
    const [create, createMutation] = useCreateClientTeamMutation();
    const [update, updateMutation] = useUpdateClientTeamMutation();
    const [deleteTemplate, deleteMutation] = useDeleteClientTeamMutation();

    const singleErrors = useMemo(() => {
        return getRtkQueryErrors(getTeamQuery.error, t.errors);
    }, [getTeamQuery.error, t.errors]);
    const allErrors = useMemo(() => {
        return getRtkQueryErrors(getAllQuery.error, t.errors);
    }, [getAllQuery.error, t.errors]);
    const createErrors = useMemo(() => {
        return getRtkQueryErrors(createMutation.error, t.errors);
    }, [createMutation.error, t.errors]);
    const updateErrors = useMemo(() => {
        return getRtkQueryErrors(updateMutation.error, t.errors);
    }, [updateMutation.error, t.errors]);
    const deleteErrors = useMemo(() => {
        return getRtkQueryErrors(deleteMutation.error, t.errors);
    }, [deleteMutation.error, t.errors]);

    return {
        single: {
            data: getTeamQuery.data,
            isFetching: getTeamQuery.isFetching,
            isLoading: getTeamQuery.isLoading,
            isSuccess: getTeamQuery.isSuccess,
            isError: getTeamQuery.isError,
            errors: singleErrors,
        },
        all: {
            data: getAllQuery.data,
            isFetching: getAllQuery.isFetching,
            isLoading: getAllQuery.isLoading,
            isSuccess: getAllQuery.isSuccess,
            isError: getAllQuery.isError,
            errors: allErrors,
        },
        create: {
            fetch: create,
            data: createMutation.data,
            isLoading: createMutation.isLoading,
            isSuccess: createMutation.isSuccess,
            isError: createMutation.isError,
            errors: createErrors,
        },
        update: {
            fetch: update,
            data: updateMutation.data,
            isLoading: updateMutation.isLoading,
            isSuccess: updateMutation.isSuccess,
            isError: updateMutation.isError,
            errors: updateErrors,
        },
        delete: {
            fetch: deleteTemplate,
            data: deleteMutation.data,
            isLoading: deleteMutation.isLoading,
            isSuccess: deleteMutation.isSuccess,
            isError: deleteMutation.isError,
            errors: deleteErrors,
        },
    };
};
